import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from './app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  isLoading: boolean;

  constructor(private appService: AppService, private router: Router) { }

  ngOnInit(): void {
    const url = window.location.href;
    const token = localStorage.getItem('AUTH');
    if (token && !url.includes('/verify') && !url.includes('/report') && !url.includes('/email-link')) {
      this.isLoading = true;
      this.appService.checkStatus().subscribe({
        next: (data) => {
          const [protocol, empty, domain, ...remainUrl] = url.split('/');
          let newUrl = ''
          if (!data.success) {
            newUrl = '/login';
          } else {
            if (remainUrl.includes('login') || (remainUrl.length === 1 && remainUrl.includes(''))) {
              newUrl = '/home/assets';
            } else if (remainUrl.length > 0) {
              newUrl = `/${remainUrl.join('/')}`;
            }
          }
          if (newUrl) {
            this.router.navigate([newUrl]);
          }
        },
        error: (e) => {
          this.isLoading = false;
        }
      });
    }
  }

}
