import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { AppResolver } from './app.resolver';
import { AuthGuard } from './auth/services/auth.guard';

const routes: Routes = [
  {
    path: 'home',
    canActivate: [AuthGuard],
    // resolve: { login: AppResolver },
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'report',
    loadChildren: () => import('./report/report.module').then(m => m.ReportModule)
  },
  {
    path: 'email-link',
    loadChildren: () => import('./email-link/email-link.module').then(m => m.EmailLinkModule)
  },
  {
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
